
const white = "#FFFFFF"
const black = "#000000"
const yellow = "#FFD600"

export function getColorLuminosity (color) {
    const c = color.substring(1) // strip #
    const rgb = parseInt(c, 16) // Parse as hex
    const r = (rgb >> 16) & 0xff
    const g = (rgb >>  8) & 0xff
    const b = (rgb >>  0) & 0xff
    return 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
}

function getColorConstrast (color1, color2) {
    return Math.abs(getColorLuminosity(color1) - getColorLuminosity(color2))
}

function hasSufficientContrast (color, ...args) {
    return args.every(c => getColorConstrast(color, c) > 50)
}

export function isBrightColor (color) {
    return getColorLuminosity(color) > 128
}

export function isDarkColor (color) {
    /* Text color should be white */
    return getColorLuminosity(color) < 128
}

function isVeryBrightColor (color) {
    return getColorLuminosity(color) > 225
}

function isVeryDarkColor (color) {
    return getColorLuminosity(color) < 65
}

export function parseColor (value) {
    // Note: This is *not* complete. Anything unrecognizable will just default to black
    if (value === undefined) return undefined
    value = value?.replaceAll(" ", "")
    if (value?.startsWith("#") && value?.length === 7) return value
    if (value === "white") return white
    if (value === "black") return black
    return black
}

/* Converts a few color inputs into all the overrides defined in index.css */
export function computeColorCssVariables ({
    lightMode=false,
    primary,
    accent=undefined,
    contrast=undefined,
    header=undefined,
    background=undefined,
    overrides={},
}) {
    
    let colors;
    primary = parseColor(primary) || "#262626"
    accent = parseColor(accent) || yellow
    contrast = parseColor(contrast) || yellow
    header = parseColor(header)

    if (lightMode) {
        background = parseColor(background)
        if (!background || !isBrightColor(background)) {
            background = white
        }

        colors = {
            "--color-scheme": "light",
            "--bg-color": background,
            "--text-color": black,
            "--secondary-text-color": "#929292",
            "--secondary-bg-color": "#F4F4F4",
            "--tertiary-bg-color": "#F4F4F4",
            "--separator-color": "#D8D8D8",
        }
    } else {
        background = parseColor(background)
        if (!background || !isVeryDarkColor(background)) {
            background = black
        }

        colors = {
            "--color-scheme": "dark",
            "--bg-color": background,
            "--text-color": white,
            "--secondary-text-color": "#B2B2B2",
            "--secondary-bg-color": white,
            "--tertiary-bg-color": "#262626",
            "--separator-color": "#8D8D8D",
        }
    }

    const textColor = lightMode ? black : white
    const headerFooterColor = header || primary
    const headerFooterTextColor = isDarkColor(headerFooterColor) ? white : black
    const headerFooterAccentColor = (
        hasSufficientContrast(accent, headerFooterColor, headerFooterTextColor, black) ? accent : (
            hasSufficientContrast(contrast, headerFooterColor, headerFooterTextColor, black) ? contrast : yellow
        )
    )
    const accentColor = isVeryDarkColor(contrast) || isVeryBrightColor(contrast) ? yellow : contrast
    const bgAccentColor = (
        hasSufficientContrast(primary, background, textColor) ? primary : (
            hasSufficientContrast(accent, background, textColor) ? accent : (
                hasSufficientContrast(contrast, background, textColor) ? contrast : yellow
            )
        )
    )
    const imageGradientColor = isVeryDarkColor(background) ? background : black

    return {
        ...colors,
        "--header-footer-color": headerFooterColor,
        "--header-footer-text-color": headerFooterTextColor,
        "--image-gradient-color": imageGradientColor,
        "--header-footer-accent-color": headerFooterAccentColor,
        "--accent-color": accentColor,
        "--bg-accent-color": bgAccentColor,
        "--option-selection-color": accentColor,
        ...overrides,
    }
}