import React, {useEffect, useState, useRef, useMemo} from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useBackend } from "../../utility/Backend"
import Backend from "../../utility/Backend"
import { capitalizeFirstLetter, useUpdateSearchParams } from "../../utility/Utilities"
import { useCheckIsAdmin } from "../../utility/UserGroups"
import Page, { LoadingPage } from "../../components/Page"
import { Helmet } from "react-helmet-async"
import UploadImage from "../../components/UploadImage"
import classNames from "classnames"
import { useGetUsersGroups } from "../../utility/UserGroups"
import { useFeedbackMessage } from "../../stores/FeedbackMessage"
import { useMutateByRegex } from "../../utility/Utilities"
import { computeColorCssVariables } from "./clubColorUtils"
import ConfirmModal from "../../components/ConfirmModal"
import Config from "../../utility/Config"
import { RedirectHome } from "../../App"
import { IoMdArrowDropright } from "react-icons/io";
import { MdOutlineDeleteSweep } from "react-icons/md";
import {FiEdit3} from "react-icons/fi";
import "./ClubConfig.css"

function ClubConfigHeroImage ({heroImage, edits, updateEdits, editMode, updateHeroImage}) {

    const getValue = (name) => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return heroImage[name]
    }
    
    const url = getValue("url")
    const position = getValue("position")
    const heroLogo = getValue("logo_url")
    const heroMessage = getValue("message")
    
    const convertPositionValue = (position) => {
        const percentageRegex = /^(100|[1-9]?[0-9])%$/;
        const isPercentage = percentageRegex.test(position);

        if (!position || position === undefined) return "50"
        else if (position === "center") return "50"
        else if (position === "top") return "0"
        else if (isPercentage) return position.replace("%", "")
    }

    const oriPositionParts = heroImage.position.split(" ");
    const [oriHorizontal, oriVertical] = oriPositionParts.length > 1 ? [convertPositionValue(oriPositionParts[0]), convertPositionValue(oriPositionParts[1])] : ["50", convertPositionValue(oriPositionParts[0])]
    const positionParts = position.split(" ");
    const [horizontal, vertical] = positionParts.length > 1 ? [convertPositionValue(positionParts[0]), convertPositionValue(positionParts[1])] : ["50", convertPositionValue(positionParts[0])]
    
    const [horizontalValue, setHorizontalValue] = useState(horizontal)
    const [verticalValue, setVerticalValue] = useState(vertical)
    const [imageObjectPosition, setImageObjectPosition] = useState("")
    const [heroImageSize, setHeroImageSize] = useState("desktop")
    const [newHeroMessage, setNewHeroMessage] = useState("")
    const [editingHero, setEditingHero] = useState(null)

    useEffect(() => {
        if (!editMode) {
            setHorizontalValue(horizontal)
            setVerticalValue(vertical)
        }
    }, [horizontal, vertical])

    useEffect(() => {
        setImageObjectPosition (`${horizontalValue}% ${verticalValue}%`)
    }, [horizontalValue, verticalValue])

    useEffect(() => {
        onChangeHero("message", newHeroMessage)
    }, [newHeroMessage])

    if (!heroImage) return null

    const onChangeImage = (imgAndUrl) => {
        updateHeroImage(imgAndUrl.imageFile, editingHero)
    }

    const originalUrl = url === heroImage.url
    const originalHorizontal = horizontalValue === oriHorizontal
    const originalVertical = verticalValue === oriVertical
    const originalHeroLogo = heroLogo === heroImage.logo_url
    const originalHeroMessage = heroMessage === heroImage.message
    
    const onChangeHero = (name, value, reset=false) => {
        updateEdits({type: "heroImage", name: name, value: value, reset: reset})
    }

    const onChangePosition = (name, direction, value, reset=false) => {
        if (direction === "horizontal") {
            setHorizontalValue(value)
        } 
        if (direction === "vertical") {
            setVerticalValue(value)
        }
        onChangeHero(name, `${horizontalValue}% ${verticalValue}%`, reset)
    }

    const onResetHeroMessage = (value) => {
        setNewHeroMessage("")
        updateEdits({type: "heroImage", name: "message", value: value, reset: true})
    }

    const homeHeroImage = (
        <div className="club-config-hero">
            <div className="hero-image-size-cont">
                <div 
                    onClick={() => setHeroImageSize("mobile")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "mobile"})}>
                    Mobile
                </div>
                <div 
                    onClick={() => setHeroImageSize("desktop")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "desktop"})}>
                    Desktop
                </div>
                <div 
                    onClick={() => setHeroImageSize("full")} 
                    className={classNames("hero-image-size", {"active": heroImageSize === "full"})}>
                    Full size
                </div>
            </div>
            <img src={url} alt="hero" style={{objectPosition: imageObjectPosition}} className={classNames("club-config-hero-img", {
                "mobile": heroImageSize === "mobile",
                "desktop": heroImageSize === "desktop",
            })}/>
            <div className={classNames("club-config-hero-change", {"active": editMode})}>
                <div onClick={() => setEditingHero("hero")} className="club-config-edit">Change</div>
                {!originalUrl && (
                    <div onClick={() => onChangeHero("url", heroImage.url, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
        </div>
    )
    
    const heroPosition = editMode ? (
        <div className="hero-position-cont">
            <div className="hero-position-part">
                <div className="hero-position-part-title">Horizontal</div>
                <div>
                    <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        step="10" 
                        value={horizontalValue} 
                        onChange={(e) => setHorizontalValue(e.target.value)}
                        onMouseUp={() => onChangePosition("position", "horizontal", horizontalValue)} 
                        className="hero-position-input"
                        />
                </div>
                {!originalHorizontal && (
                    <div onClick={() => onChangePosition("position", "horizontal", oriHorizontal, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
            <div className="hero-position-part">
                <div className="hero-position-part-title">Vertical</div> 
                <div>
                    <input 
                        type="range" 
                        min="0" 
                        max="100" 
                        step="10" 
                        value={verticalValue} 
                        onChange={(e) => setVerticalValue(e.target.value)}
                        onMouseUp={() => onChangePosition("position", "vertical", verticalValue)} 
                        className="hero-position-input"
                        />
                </div>
                {!originalVertical && (
                    <div onClick={() => onChangePosition("position", "vertical", oriVertical, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
            </div>
        </div>
    ) : (
        <div className="hero-position-cont">
            <div className="hero-position-part">
                <div className="hero-position-part-title">Horizontal</div>
                <div>{horizontalValue}%</div>
            </div>
            <div className="hero-position-part">
                <div className="hero-position-part-title">Vertical</div>
                <div>{verticalValue}%</div>
            </div>
        </div>
    )

    const homeHeroLogo = (
        <div className="club-config-hero-logo">
            {heroLogo ? (
                <img src={heroLogo} alt="hero logo" className="club-config-hero-logo-img"/>
            ) : (
                <div className="grey-color-text">
                    None (Default)
                </div>
            )}
            <div className={classNames("club-config-hero-change", {"active": editMode})}>
                <div onClick={() => setEditingHero("hero_logo")} className="club-config-edit">
                    {heroLogo ? "Change" : "Add"}
                </div>
                {!originalHeroLogo && (
                    <div onClick={() => onChangeHero("logo_url", heroImage.logo_url, true)} className="config-reset-btn">
                        Reset
                    </div>
                )}
                {heroImage.logo_url && (
                    <div onClick={() => onChangeHero("logo_url", null)} className="config-reset-btn">
                        Remove
                    </div>
                )}
            </div>
        </div>
    )

    const homeHeroMessage = editMode ? (
        <div className="club-config-message">
            <input 
                type="text"
                value={newHeroMessage || heroMessage || ""}
                onChange={(e) => setNewHeroMessage(e.target.value)} 
                className="hero-message-input"
                />
            {!originalHeroMessage && (
                <div onClick={() => onResetHeroMessage(heroImage.message)} className="config-reset-btn">
                    Reset
                </div>
            )}
        </div>
    ) : (
        <div className={classNames("club-config-message", {"grey-color-text": !heroMessage})}>
            {heroMessage || "None (Default)"}
        </div>
    )
    
    return (
        <div className="club-config-section">
            <div className="club-config-section-title">Home page</div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Hero image</div>
                <div className="club-config-data-value">
                    {homeHeroImage}
                </div>
            </div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Position</div>
                <div className="club-config-data-value">
                    {heroPosition}
                </div>
            </div>
            <div className="club-config-data align-start">
                <div className="club-config-data-title">Hero logo</div>
                <div className="club-config-data-value">
                    {homeHeroLogo}
                </div>
            </div>
            <div className="club-config-data">
                <div className="club-config-data-title">Hero message</div>
                <div className="club-config-data-value">
                    {homeHeroMessage}
                </div>
            </div>
            {editingHero && (
                <UploadImage 
                    onClose={() => setEditingHero(null)}
                    onChange={onChangeImage}/>
            )}
        </div>
    )
}

function ClubConfigColorBooleanSelection ({name, colors, edits, updateEdits, editMode}) {

    const getColorValue = () => {
        const override = edits.find((e) => e.name === name)
        if (override) return override.value
        else return colors[name]
    }
    const value = getColorValue()
    
    const onChangeColor = (name, value, reset=false) => {
        updateEdits({type: "color", name: name, value:value, reset: reset})
    }

    const isOriginal = value === colors[name]

    return (
        <div className="club-config-data">
            <div className="club-config-data-title">Light mode</div>
            {editMode ? (
                <>
                    <div className="club-config-data-value toggle">
                        <div onClick={() => onChangeColor(name, !value)} className={classNames("toggle-switch", {"active": value})}>
                            <div className="toggle-button"></div>
                        </div>
                    </div>
                    {!isOriginal && (
                        <div className="set-to-original">
                            {/* <div className="club-config-dot"></div> */}
                            <div onClick={() => onChangeColor(name, colors[name], true)} className="config-reset-btn">
                                Reset
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="club-config-data-value toggle">{value ? "Yes" : "No"}</div>
            )}
        </div>
    )
}

function ClubConfigColorSelection ({name, colors, edits, updateEdits, editMode, advancedColors=false}) {

    const mainColor = colors[name]
    
    const [newColor, setNewColor] = useState(mainColor)
    const [resetRenderKey, setResetRenderKey] = useState(0)
    const isInitialRender = useRef(true);

    if (advancedColors) {
        // console.log(colors)
        // console.log(name, mainColor, newColor)
    } 

    const overrideColorValue = () => {
        if (!advancedColors) return null
        const override = edits.find((e) => e.name === name)
        return override?.value || null
    }
    const overrideColor = overrideColorValue()
    
    // TODO find better solution for updating color state
    useEffect(() => {
        if (advancedColors && overrideColor) return
        setNewColor(mainColor)
    }, [mainColor])

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         isInitialRender.current = false;
    //         return
    //     }

    //     const type = advancedColors ? "css" : "color"
    //     if (newColor === "") updateEdits({type: type, name: name, value: ""})
    //     if (inputIsHex) updateEdits({type: type, name: name, value: newColor})
    // }, [newColor])

    const onTypeColor = (color) => {

        setNewColor(color)

        const type = advancedColors ? "css" : "color"
        const inputIsHex = /^#[A-Fa-f0-9]{6}$/.test(color)
        if (inputIsHex) updateEdits({type: type, name: name, value: color})
        if (color === "") updateEdits({type: type, name: name, value: ""})
    }
    
    const onChangeColor = (name, value=undefined, reset=false) => {
        setNewColor(value)
        if (value === "") value = undefined
        updateEdits({type: advancedColors ? "css" : "color", name: name, value: value, reset: reset})
        if (reset) setResetRenderKey(prev => prev + 1)
    }

    const isOriginal = newColor === colors[name]

    const lightMode = colors.lightMode
    const backgroundFollowsLightMode = name === "background" && !mainColor

    const colorNameTitle = name.replace(/--/g, "").replace(/-/g, " ")
    
    const checkShowRemove = () => {
        const removeAbleColor = ["header", "background"]
        if (!removeAbleColor.includes(name)) return false
        if (colors[name] && !!newColor) return true
    }
    const showRemove = checkShowRemove()
    
    const isColorSchemeInAdvanced = name === "--color-scheme"
    const isBgColorInAdvanced = name === "--bg-color"
    if (isColorSchemeInAdvanced || isBgColorInAdvanced) return null

    return (
        <div className={classNames("club-config-data", {"advanced-color" : advancedColors})}>
            <div className="club-config-data-title">
                {capitalizeFirstLetter(colorNameTitle)}
            </div>
            <div className="club-config-data-value color">
                {editMode ? (
                    <div key={resetRenderKey} className="data-color">
                        <input 
                            type="color"
                            disabled={!editMode}
                            value={newColor}
                            onChange={(e) => onChangeColor(name, e.target.value)}
                            className={classNames("data-color-box", {
                                "active": editMode,
                            })}
                            />
                        <input 
                            type="text" 
                            value={newColor || ""}
                            onChange={(e) => onTypeColor(e.target.value)}
                            className="new-color-input"/>
                        {!isOriginal && (
                            <div onClick={() => onChangeColor(name, colors[name], true)} className="config-reset-btn">
                                Reset
                            </div>
                        )}
                        {showRemove && (
                            <div onClick={() => onChangeColor(name, "")} className="config-reset-btn">
                                Remove
                            </div>
                        )}        
                    </div>
                ) : (
                    <>
                        {backgroundFollowsLightMode ? (
                            <div className="background-light-mode-info">
                                Background in accordance to {lightMode === true && "light"} {lightMode === false && "dark"} mode {lightMode ? "(white)" : "(black)"}
                            </div>
                        ) : (
                            <div className="data-color">
                                {mainColor && (
                                    <div style={{backgroundColor: mainColor}} className="config-color-box"></div>
                                )}
                                {mainColor || "None"}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

function ClubConfigColors ({colors, css, edits, updateEdits, editMode}) {
    
    const [openAdvancedColors, setOpenAdvancedColors] = useState(false)

    useEffect(() => {
        if (!editMode && openAdvancedColors) setOpenAdvancedColors(false)
    }, [editMode])

    if (!colors || !css) return null
    
    const colorsNames = ["primary", "accent", "contrast", "header", "background"]
    
    const advancedColors = (
        <div className="club-config-data align-start margin-top">
            <div className="club-config-data-title">Advanced colors</div>
            <div className={classNames("advanced-colors-cont", {"show": openAdvancedColors})}>
                <div onClick={() => setOpenAdvancedColors(!openAdvancedColors)} className="club-config-dropdown-toggle">
                    {openAdvancedColors ? "Close" : "Open"} <IoMdArrowDropright />
                </div>
                <div className="advanced-colors">
                    {/* <div className="advanced-colors-disclaimer">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Excepturi distinctio id labore. Delectus excepturi unde sunt, officia nobis maxime animi culpa. Dicta assumenda consequatur id est officia nostrum maxime sit sint esse facere accusamus veniam
                    </div> */}
                    {Object.entries(css).map(([key,]) => {
                        return (
                            <ClubConfigColorSelection 
                                key={key} 
                                name={key} 
                                colors={css}
                                edits={edits}
                                updateEdits={updateEdits}
                                editMode={editMode}
                                advancedColors/>
                        )
                    })}
                </div>
            </div>
        </div>  
    )

    return (
        <div className="club-config-section">
            <div className="club-config-section-title">Colors</div>
            {colorsNames.map((n) => {
                return (
                    <ClubConfigColorSelection 
                        key={n}
                        name={n}
                        colors={colors}
                        edits={edits}
                        updateEdits={updateEdits}
                        editMode={editMode}
                        />
                )
            })}
            <ClubConfigColorBooleanSelection 
                name={"lightMode"}
                colors={colors}
                edits={edits}
                updateEdits={updateEdits}
                editMode={editMode}
                />
            {advancedColors}
        </div>
    )
}

function ClubConfigSelection ({clubConfig, editMode, setEditMode, previewConfig, submitConfig, previewData, resetEdit}) {

    const [searchParams, updateSearchParams,] = useUpdateSearchParams()

    const [discardModalOpen, setDiscardModalOpen] = useState(false)

    const configParam = searchParams.get("config")
    const clubConfigOptions = ["homepage", "colors"]
    const team = clubConfig.team 

    const updateParam = (section) => {
        updateSearchParams("config", section)
    }

    const onEditConfiguration = () => {
        setEditMode(true)
        previewConfig()
    }

    const {showFeedback} = useFeedbackMessage();

    const copyPreviewLink = (e) => {
        if (!previewData) return
        e.stopPropagation();
        navigator.clipboard.writeText(`${Config.webHref}${previewData.route}?config_preview_id=${previewData.preview_id}`)
        showFeedback("success", "Preview link copied to clipboard");
    }

    const discardEditModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setDiscardModalOpen(false)} 
            onConfirm={resetEdit} 
            cancelText="Cancel" 
            confirmText="Discard"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdOutlineDeleteSweep/></div>
                <div className="confirm-title">Are you sure you want to discard the changes?</div>
            </div>
        </ConfirmModal>
    )

    const configButtons = editMode ? (
        <div className="club-config-button-cont">
            <button onClick={() => setEditMode(false)} className="discard-configuration">
                Discard
            </button>
            <button onClick={copyPreviewLink} className="copy-preview-link">
                Copy preview link
            </button>
            <button onClick={submitConfig} className="submit-configuration">
                Submit
            </button>
        </div>
    ) : (
        <div className="club-config-button-cont">
            <button onClick={onEditConfiguration} className="edit-configuration">
                Edit configuration
            </button>
        </div>
    )

    return (
        <div className="club-config-option-cont">
            <img src={team?.logo_url} alt="team logo" className="club-config-team-logo"/>
            {clubConfigOptions.map((s) => {
                return (
                    <div key={s} onClick={() => updateParam(s)} 
                        className={classNames("club-config-option", {"active": s === configParam})}>
                        {capitalizeFirstLetter(s)}
                    </div>
                )
            })}
            {configButtons}
            {discardModalOpen && discardEditModal}
        </div>
    )
}

function ClubConfigSection ({clubConfig, editMode, setEditMode, mutate}) {

    const {colors, css, hero_image: heroImage} = clubConfig

    const [edits, setEdits] = useState([])
    const [previewData, setPreviewData] = useState(null)
    const [cssVariables, setCssVariables] = useState(null)

    const [searchParams,,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team")

    const computeCss = () => {
        const computed = computeColorCssVariables(colors)
        setCssVariables(computed)
    }
    
    useEffect(() => {
        computeCss()
    }, [colors])
    
    const resetEdit = () => {
        setEdits([])
        setPreviewData(null)
        setEditMode(false)
        computeCss()
    }

    useEffect(() => {
        resetEdit()
    }, [teamParam])

    useEffect(() => {
        if (!editMode) resetEdit() 
    }, [editMode])

    const previewWindowRef = useRef(null);

    // Open the child window only once, when the component mounts
    useEffect(() => {
        if (!previewData) return
        if (!previewWindowRef.current || previewWindowRef.current.closed) {
            previewWindowRef.current = window.open(
                `${Config.webHref}${previewData.route}?config_preview_id=${previewData.preview_id}`, 
                "childWindow", 
                "width=800,height=600,resizable=yes,scrollbars=yes"
            );
        }
        // Cleanup when the component unmounts
        return () => {
            if (previewWindowRef.current && !previewWindowRef.current.closed) {
                previewWindowRef.current.close();
            }
        }
    }, [previewData])

    // Send messages to the existing child window whenever edits change

    const sendMessageToChildWindow = () => {
        if (previewWindowRef.current) {
            const object = {source: "forzify-mam", command: "fetch", object: edits}
            previewWindowRef.current.postMessage(object, Config.webHref)
            console.log("sending message to " + Config.webHref)
        }
    }
    
    // useEffect(() => {
    //     const checkWindowClosed = setInterval(() => {
    //         if (previewWindow && previewWindow.closed) {
    //             setPreviewWindowIsOpen(false)
    //         }
    //     }, 1000)
    //     return () => clearInterval(checkWindowClosed)
    // }, [previewWindow])

    const {showFeedback} = useFeedbackMessage()
    const mutateByRegex = useMutateByRegex()
    
    // all edits that are not "reset" / overrode colors
    const activeEdits = edits.filter((e) => !e.reset)
    
    function updateEdits (newEdit) {

        const updatedEdits = structuredClone(edits)
        // Find the index of the object with the same name
        const index = updatedEdits.findIndex(edit => edit.name === newEdit.name);
        
        // If the name is already in the object, overwrite the value 
        if (index !== -1) updatedEdits[index] = newEdit
        // Add new name and value
        else updatedEdits.push(newEdit);

        setEdits(updatedEdits)
    }
    
    const computeConfigObject = (configType, active=false, css=false) => {

        let config
        
        if (configType === "heroImage") config = heroImage
        if (configType === "color") config = colors
        if (configType === "css") config = cssVariables

        let updatedConfigObject

        const editsConfig = active ? activeEdits : edits
        const configOverrides = editsConfig.filter((e) => {return e.type === configType})
        
        // If there's overrides, overwrite it/them to the original config 
        if (configOverrides.length !== 0) {
            const configOverridesObject = configOverrides.reduce((acc, value) => (
                { ...acc, [value.name]: value.value }
            ), {})
            const updatedObject = {...config, ...configOverridesObject}
            updatedConfigObject = updatedObject
        } 
        else if (css) {
            updatedConfigObject = config
        }
        // if (configType === "heroImage") {
        //     if ("logo_url" in updatedConfigObject) delete updatedConfigObject.logo_url
        //     if ("message" in updatedConfigObject) delete updatedConfigObject.message
        // }
        return updatedConfigObject
    }
    // Objects that need to be updated (active edits and resets)
    const heroImageObject = computeConfigObject("heroImage")
    const colorsObject = computeConfigObject("color")
    const cssObject = computeConfigObject("css", true, true)
    
    // Objects with only active edits (no resets)
    // These are for submitting the changes
    const heroImageObjectActive = computeConfigObject("heroImage", true)
    const colorsObjectActive = computeConfigObject("color", true)
    const includeCss = !!colorsObjectActive || !!computeConfigObject("css", true)

    useEffect(() => {
        if (edits.length === 0) return
        const colors = colorsObject || css
        const computed = computeColorCssVariables(colors)
        setCssVariables(computed)
    }, [edits])

    useEffect(() => {
        if (!editMode) return 

        const updateTimeout = setTimeout(() => {
            if (heroImageObject || colorsObject || cssObject) {
                updatePreviewConfig()
                if (previewWindowRef.current) {
                    const object = {source: "forzify-mam", command: "fetch", object: edits}
                    previewWindowRef.current.postMessage(object, Config.webHref)
                    console.log("sending message to " + Config.webHref)
                }
            }
        }, 3000)
        return () => clearTimeout(updateTimeout);

    }, [edits, heroImageObject, colorsObject, cssObject])
    
    const previewConfig = async () => {

        const configForPreview = {}

        console.log("POST", configForPreview)

        await Backend.post(`/fotbollplay/team/${clubConfig.team.id}/clubconfig/preview`, JSON.stringify(configForPreview))
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed to create preview" + error);
                } else {
                    setPreviewData(data)
                    showFeedback("success", "Configuration preview in new window");
                }
            })
    }

    const updateHeroImage = async (imageFile, type) => {

        let imageName
        if (type === "hero") imageName = "url"
        if (type === "hero_logo") imageName = "logo_url"

        let body = new FormData();
        body.append("image", imageFile)
        const image = body;
        await Backend.post(`/userimage?type=${type}`, image, {}, {json:false})
            .then(({data, error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", "Failed change hero image, " + error);
                } else {
                    console.log(data)
                    updateEdits({type: "heroImage", name: imageName, value: data.path})
                    console.log("Hero image updated")
                }
            })
    }
    
    const updatePreviewConfig = async () => {

        const configForPreview = {}

        if (heroImageObject) configForPreview.hero_image = heroImageObject
        if (colorsObject) configForPreview.colors = colorsObject
        if (cssObject) configForPreview.css = cssObject
        
        console.log("PUT", configForPreview)
        
        await Backend.put(`/fotbollplay/clubconfig/preview/${previewData.preview_id}`, JSON.stringify(configForPreview))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to update preview" + error);
                } else {
                    
                }
            })
    }

    const submitConfig = async () => {

        const configForSubmit = {}

        if (colorsObjectActive) configForSubmit.colors = colorsObjectActive
        if (heroImageObjectActive) configForSubmit.hero_image = heroImageObjectActive
        if (includeCss) configForSubmit.css = cssObject
        
        console.log("POST", configForSubmit)

        Backend.put(`/fotbollplay/team/${previewData?.id}/clubconfig`, JSON.stringify(configForSubmit))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to PUT", error);
                    showFeedback("warning", "Failed to submit club config" + error);
                } else {
                    resetEdit()
                    showFeedback("success", "Club config submitted");
                    mutate()
                    // mutateByRegex(/^\/club_config/)
                }
            })
    }

    return (
        <div className="club-config-cont">
            <ClubConfigSelection 
                clubConfig={clubConfig} 
                editMode={editMode} 
                setEditMode={setEditMode} 
                previewConfig={previewConfig} 
                submitConfig={submitConfig} 
                previewData={previewData}
                resetEdit={resetEdit}
                />
            <div className="club-config-section-cont">
                <ClubConfigHeroImage 
                    heroImage={heroImage} 
                    edits={activeEdits} 
                    updateEdits={updateEdits} 
                    editMode={editMode} 
                    updateHeroImage={updateHeroImage}
                    />
                <ClubConfigColors 
                    colors={colors} 
                    css={cssVariables} 
                    edits={activeEdits} 
                    updateEdits={updateEdits} 
                    editMode={editMode} 
                    />
            </div>
        </div>
    )
}

function ClubConfigTeamOptions ({allConfigs, editMode}) {

    const [,updateSearchParams,] = useUpdateSearchParams() 

    const [isEditingWarningOpen, setIsEditingWarningOpen] = useState(false)

    const allTeams = allConfigs.map((c) => {return c.team}) || []
    
    if (allTeams.length === 0) return null

    const onSelectTeam = (teamId) => {
        if (editMode) {
            setIsEditingWarningOpen(true)
            return
        } else {
            updateSearchParams("team", teamId)
        }
    }

    const editingWarningModal = isEditingWarningOpen && (
        <ConfirmModal 
            isOpen
            onClose={() => setIsEditingWarningOpen(false)} 
            cancelText="Back" 
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FiEdit3/></div>
                <div className="confirm-title">You are editing a club config</div>
                <div className="confirm-title">Discard the edit to select other team</div>
            </div>
        </ConfirmModal>
    )
    
    return (
        <div className="club-config-team-cont">
            {allTeams.map((t) => {
                return (
                    <img 
                        key={t.id}
                        src={t.logo_url} 
                        alt="team logo"
                        onClick={() => onSelectTeam(t.id)} 
                        className="club-config-team-select"/>
                )
            })}
            {editingWarningModal}
        </div>
    )
}

function ClubConfigWrapper () {
    
    const [,isAdmin] = useCheckIsAdmin()
    const usersUserGroups = useGetUsersGroups()
    const [searchParams, updateSearchParams,] = useUpdateSearchParams()    
    const teamParam = searchParams.get("team")

    const {data, mutate} = useBackend("/fotbollplay/clubconfig?nocache")
    const clubConfigData = data || []
    const allConfigs = Object.values(clubConfigData).flat() || []
    const firstConfigTeamId = isAdmin && allConfigs[0]?.id 

    const [editMode, setEditMode] = useState(false)

    // set team param for admin on first render, as admin can open all clubs' config
    useEffect(() => {
        if (!isAdmin || teamParam) return
        updateSearchParams("team", firstConfigTeamId)
    }, [firstConfigTeamId])
    
    if (!data) return <LoadingPage/>

    // Admin, get config from team param
    const configFromParam = allConfigs.find((c) => c.id === parseInt(teamParam))

    // Non admin, get config from team's user group id
    const teamConfig = () => {
        const userGroupId = usersUserGroups[0]?.id
        const teamConfig = allConfigs.find((c) => c.usergroup_id === userGroupId)
        return teamConfig
    }

    const clubConfig = isAdmin ? configFromParam : teamConfig()
    
    if (!clubConfig) return <LoadingPage/> 
    
    const selectTeam = (
        <div className="club-config-start-message">Select team to configure</div>
    )

    const noAccess = (
        <div>You have no access to this page</div>
    )

    return (
        <Page centered title="Club config">
            <Helmet>
                <title>Club Config</title>
            </Helmet>
            <div className="club-config-main">
                {isAdmin && <ClubConfigTeamOptions allConfigs={allConfigs} editMode={editMode}/>}
                <ClubConfigSection clubConfig={clubConfig} editMode={editMode} setEditMode={setEditMode} mutate={mutate}/>
            </div>
        </Page>
    )
}

function ClubConfig () {

    const isSef = Config.association === "SEF"

    useEffect(() => {
        if (!isSef) RedirectHome()
    }, [])

    if (!isSef) return null

    return <ClubConfigWrapper/>
}

export default ClubConfig