import React, {useState} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { LoadingPage } from "../../components/Page";
import ConfirmModal from "../../components/ConfirmModal";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import { useMutateByRegex } from "../../utility/Utilities";
import {v4 as uuid} from "uuid";
import {Link} from "react-router-dom";
import { MdAddHome } from "react-icons/md";
import "./Teams.css";

function MerchantSubscription ({merchantId}) {

    const {data} = useBackend(merchantId ? "/cee_merchant/" + merchantId + "/subscription?nocache" : "")
    
    if (!data) return "-"

    const subscriptions = data?.subscriptions

    if (subscriptions.length === 0) return "-"
    
    const renderSubscriptions = subscriptions.map((s) => {
        const pricePlan = s.price_plans[0]
        return (
            <div key={s.id} className="subscription-info-cont">
                <div className="subscription-info-name">
                    {s.name}
                </div>
                <div className="subscription-info">
                    <div className="subscription-info-title">
                        Price : 
                    </div>
                    <div className="subscription-info-value">
                        {pricePlan.price_amount} {pricePlan.price_currency}
                    </div>
                </div>
                <div className="subscription-info">
                    <div className="subscription-info-title">
                        Plan : 
                    </div>
                    <div className="subscription-info-value">
                        {pricePlan.name}
                    </div>
                </div>
            </div>
        )
    })
    
    return (
        <div>{renderSubscriptions}</div>
    )
}

function TeamFotbollPlay ({refresh, teamId}) {

    const [isFsAdmin,] = useCheckIsAdmin()
    const {showFeedback} = useFeedbackMessage()
    const mutateByRegex = useMutateByRegex()

    const [addMerchant, setAddMerchant] = useState(false)

    const {data} = useBackend("/fotbollplay/team/" + teamId + "/clubconfig?nocache")

    if (!data) return <LoadingPage/>

    const clubConfig = data
    
    const {channels, merchant, team, route} = clubConfig
    
    const teamChannels = channels.length !== 0 ? (
        <div className="team-channels-cont">
            {channels.map((c) => {
                return <div key={c.id}>{c.name}</div>
            })}
        </div>
    ) : "-"

    const openAddMerchant = () => {
        setAddMerchant(true)
    }

    const onAddMerchant = () => {
        const newMerchantProps = {cee_merchant_id: uuid()}
        Backend.post("/fotbollplay/team/" + team.id + "/merchant", JSON.stringify(newMerchantProps))
            .then(({error}) => {
                if (error) {
                    console.error("Failed to POST", error);
                    showFeedback("warning", error);
                } else {
                    showFeedback("success", "Merchant created successfully");
                    console.log("Merchant created");
                    mutateByRegex(/^\/fotbollplay\//)
                }
            })
    }

    const addMerchantConfirmation = (
        <ConfirmModal 
            isOpen
            onClose={() => setAddMerchant(false)}
            onConfirm={onAddMerchant} 
            confirmText = "Create"
            cancelText="Cancel"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdAddHome/></div>
                <div className="confirm-title">Create a merchant for {team.name}?</div>
            </div>
        </ConfirmModal>
    )

    const addMerchantAvailable = isFsAdmin && !merchant

    // Only for FSA
    const addClubMerchant = addMerchantAvailable && (
        <div className="confirm-cancel-btn-cont margin-top">
            <button onClick={openAddMerchant}>
                {/* <FiEdit3 className="icon-in-btn"/> */}
                Add Merchant
            </button>
            {addMerchant && addMerchantConfirmation}
        </div>
    )

    return (
        <div className="data-info-cont">
            <div className="data-info-head-title">Fotboll Play</div>
            <div className="data-info">
                <div className="data-info-title">Fotboll Play</div>
                <div className="data-info-value">
                    <a href={`https://fotbollplay.se${route}`} target="_blank" rel="noreferrer">
                        {`https://fotbollplay.se${route}`}
                    </a>
                </div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Channels</div>
                <div className="data-info-value">{teamChannels}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Merchant</div>
                <div className="data-info-value">{merchant?.name || "-"}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Merchant ID</div>
                <div className="data-info-value">{merchant?.id || "-"}</div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Subscriptions</div>
                <div className="data-info-value"><MerchantSubscription merchantId={merchant?.id}/></div>
            </div>
            <div className="data-info">
                <div className="data-info-title">Club config</div>
                <div className="data-info-value">
                    <Link to={`/club_config/${team.id}?config=homepage`}>Configure club config</Link>
                </div>
            </div>
            {addClubMerchant}
        </div> 
    )
}

export default TeamFotbollPlay